import React from 'react'
import { graphql, Link } from 'gatsby'
import styles from './team.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TeamMember from '../components/teamMemberPageComponent'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const TeamPage = ({ data }) => (
   <Layout>
      <SEO title="Our Financial Team" />
      <div className={['page', styles.page__team].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Our Financial Team
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               We encourage you to meet our human-centric team of wealth
               management and tax-integrated advisors.
               <Link to="/legal/disclaimers#tax">*</Link>
            </p>
         </div>
         <Tabs
            className="l-tabs"
            selectedTabClassName="l-tabs__tab--selected"
            selectedTabPanelClassName="l-tabs__panel--selected">
            <div className={['tab--navigation', styles.team__tabs].join(' ')}>
               <TabList className="l-tabs__tab-list">
                  <Tab className="l-tabs__tab">All</Tab>
                  <Tab className="l-tabs__tab">Advisors</Tab>
                  <Tab className="l-tabs__tab">CPA's</Tab>
                  <Tab className="l-tabs__tab">Financial Associates</Tab>
                  <Tab className="l-tabs__tab">Financial Planning</Tab>
                  <Tab className="l-tabs__tab">Client Service</Tab>
                  <Tab className="l-tabs__tab">Operations</Tab>
               </TabList>
            </div>

            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee && 
                     data.allContentfulEmployee.edges.map(({ node }, index) => (
                        <TeamMember
                           key={index}
                           name={node.name}
                           nickname={node.nickname}
                           slug={node.slug}
                           imageFluid={node.image?.fluid}
                           imageTitle={node.image?.title}
                           certifications={node.certifications}
                           roleSpc={node.roleSpc}
                           roleSm={node.roleSm}
                           hasBiography={node.biography.id ? true : false}
                           linkedIn={node.linkedIn}
                        />
                     ))}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes('Advisors') && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes("CPA's") && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes('Financial Associates') && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes('Financial Planning') && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes('Client Service') && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.team__members}>
                  {data.allContentfulEmployee &&
                     data.allContentfulEmployee.edges.map(
                        ({ node }, index) =>
                           node.teams.includes('Operations') && (
                              <TeamMember
                                 key={index}
                                 name={node.name}
                                 nickname={node.nickname}
                                 slug={node.slug}
                                 imageFluid={node.image?.fluid}
                                 imageTitle={node.image?.title}
                                 certifications={node.certifications}
                                 roleSpc={node.roleSpc}
                                 roleSm={node.roleSm}
                                 hasBiography={node.biography.id ? true : false}
                                 linkedIn={node.linkedIn}
                              />
                           )
                     )}
               </div>
            </TabPanel>
         </Tabs>
      </div>
   </Layout>
)

export default TeamPage

export const query = graphql`
   query {
      allContentfulEmployee(
         filter: { node_locale: { eq: "en-US" } }
         sort: { fields: [priority, certifications, yearJoined], order: ASC }
      ) {
         edges {
            node {
               name
               slug
               nickname
               linkedIn
               certifications
               yearJoined
               image {
                  title
                  fluid(maxWidth: 300) {
                     ...GatsbyContentfulFluid_tracedSVG
                     # ...GatsbyContentfulFluid
                  }
               }
               roleSpc
               roleSm
               teams
               biography {
                  id
               }
            }
         }
      }
   }
`

import React, { Component } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Img from 'gatsby-image'
import styles from './team.module.sass'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

let none = 'none'

class TeamMember extends Component {
   render() {
      return (
         <div
            className={[
               styles.team__member,
               this.props.className,
               !!this.props.isBiographyPage ? styles.isBiographyPage : '',
            ].join(' ')}>
            <div
               className={[
                  styles.content,

                  this.props.certifications ? styles.hasTags : '',
               ].join(' ')}
               style={{
                  margin: this.props.margin,
                  width: this.props.width,
                  gridTemplateColumns: this.props.columns,
               }}>
               <a
                  href={['/team/', this.props.slug].join('')}
                  style={{
                     cursor: !this.props.isBiographyPage ? '' : 'default',
                  }}>
                  {this.props.imageFluid && (
                     <Img
                        fluid={this.props.imageFluid}
                        alt={this.props.imageTitle}
                        className={styles.image}
                        style={{ position: 'absolute' }}
                     />
                  )}
                  {this.props.certifications && (
                     <div className={styles.tags}>
                        {this.props.certifications &&
                           this.props.certifications
                              .slice(0)
                              .reverse()
                              .map((certification, index) => (
                                 <Tippy
                                    key={index}
                                    content="Click to view our Professional Designation Glossary"
                                    theme="translucent">
                                    <div className={styles.tag}>
                                       <object type="spc/financial">
                                          <a
                                             href="https://spcfinancial.com/go/ProfessionalDesignationGlossary"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                             data-tip='Click to view our<br/>"Professional Designation Glossary"'>
                                             {certification}
                                          </a>
                                       </object>
                                    </div>
                                 </Tippy>
                              ))}
                     </div>
                  )}{' '}
                  <h4 className={styles.name}>{this.props.name}</h4>
                  <div className={styles.roles}>
                     {this.props.roleSpc && (
                        <div className={styles.role}>
                           {this.props.roleSpc}{' '}
                           <span>
                              &mdash; SPC Financial<sup>&reg;</sup>
                           </span>
                           <span className={styles.short}>| SPC</span>
                        </div>
                     )}
                     {this.props.roleSm && (
                        <div className={styles.role}>
                           {this.props.roleSm}{' '}
                           <span>&mdash; Sella &amp; Martinic</span>
                           <span className={styles.short}>
                              |{' '}
                              <object type="spc/financial">
                                 <a
                                    href="//sellamartinic.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-tip="Sella &amp; Martinic, LLC">
                                    S&amp;M
                                 </a>
                              </object>
                           </span>
                        </div>
                     )}
                  </div>
               </a>
               {(this.props.hasBiography || this.props.linkedIn) && (
                  <div className={styles.actions}>
                     {this.props.hasBiography && (
                        <div
                           className={[styles.action, styles.biography].join(
                              ' '
                           )}>
                           <object type="spc/financial">
                              <a
                                 href={['/team/', this.props.slug].join('')}
                                 className="button button--secondary button--small">
                                 <b>Meet</b>{' '}
                                 {this.props.nickname
                                    ? this.props.nickname
                                    : this.props.name.replace(/ .*/, '')}
                              </a>
                           </object>
                        </div>
                     )}
                     {this.props.linkedIn && (
                        <div
                           className={[styles.action, styles.linkedin].join(
                              ' '
                           )}>
                           <OutboundLink
                              href={this.props.linkedIn}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="button button--primary button--small"
                              aria-label={
                                 this.props.nickname
                                    ? this.props.nickname + "'s LinkedIn"
                                    : this.props.name.replace(/ .*/, '') +
                                      "'s LinkedIn"
                              }>
                              <span style={{ display: none }}>
                                 {this.props.nickname
                                    ? this.props.nickname + "'s "
                                    : this.props.name.replace(/ .*/, '') + ' '}
                              </span>
                              <FontAwesomeIcon icon={faLinkedinIn} />
                           </OutboundLink>
                        </div>
                     )}
                  </div>
               )}
            </div>
         </div>
      )
   }
}

export default TeamMember
